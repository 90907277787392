import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { getInstaAppInfo, getInstaUser } from "../../api/instagram-api";
import { router_insta_auth } from "../../types/enum";
import { localStorageGet, localStorageSet } from "../../utils/storage";
import queryString from "query-string";
const InstagramAuth = () => {
  // get router querys
  const history = useHistory();

  useEffect(() => {
    const queryKV = queryString.parse(history.location.search) as {
      [k: string]: string;
    };
    let { from, code } = queryKV;
    if (from && !code) {
      // here from app
      localStorageSet("inst_ap", null);
      getInstaAppInfo().then((info: any) => {
        const { key } = info;
        info.from = from;
        localStorageSet("inst_ap", info);
        const parsed = {
          enable_fb_login:0,
          force_authentication:1,
          client_id:key,
          redirect_uri: window.location.origin + router_insta_auth,
          response_type:'code',
          scope:'instagram_business_basic'
        }
        window.location.href = `https://www.instagram.com/oauth/authorize?` + queryString.stringify(parsed);
        // window.location.href = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=2995978860562817&redirect_uri=https://staging.thefield.org/insta-auth&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`
        // window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${key}&redirect_uri=${window.location.origin}${router_insta_auth}&scope=public_profile,user_media&response_type=code`;
      });
    } else if (code) {
      // redirect from instagram with code
      const info = localStorageGet("inst_ap");
      if (info.waiting) {
        return
      }

      localStorageSet("inst_ap", { ...info, waiting: true });
      code = code.split("#")[0];

      getInstaUser(code)
        .then((instaUser) => {
          const from = info?.from;
          if (from) {
            localStorageSet("inst_user", instaUser);
            history.push(from);
          }
        })
        .catch((err) => {
          message.error((err.message || err).toString());
        });
    } else {
      // error
      message.error("some error, back to homepage");
      history.push("/");
    }
    return
  }, [history])
  return <br />;
};

export default InstagramAuth;
