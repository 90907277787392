import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

import { Alert, Collapse } from "antd";
import styled from "styled-components";

import { useModel } from "use-reaction";
import { user, getUser, loginOut } from "@/model/user";

import MenuLayout from "@/components/layouts/MenuLayout";

import { backToAdmin } from "@/api/sign-api";

import { ReactComponent as Logo } from "@/images/logo-text.svg";
import user_img from "@/images/admin_icon_user.svg";

import { getEnv } from "@/libs/cfg";
import { view } from "@/model/view";
import { DashboardHeaderMobile } from "./DashboardHeaderMobile";
import "@/styles/dashboard/admin.less";
import "./DashboardLayout.less";
import { UserRole } from "@/types/enum";

const DashboardLayoutContainer = styled.div``;

interface props {
  children?: React.ReactNode;
}

const DashboardLayout = ({ children }: props) => {
  const history = useHistory();
  const { store, doAction } = useModel(user);
  const {
    store: { isMobile },
  } = useModel(view);
  const onSignOut = () => {
    doAction(loginOut);
    history.replace("/sign/in");
  };

  const handleAdmin = () => {
    backToAdmin().then((data) => {
      window.location.href = `${getEnv("ADMIN_HOST")}/auth/${data.token}`;
      doAction(loginOut);
    });
  };

  useEffect(() => {
    doAction(getUser);
    // eslint-disable-next-line
  }, []);

  const [showAlert,setShowAlert] = useState(true);

  return (
    <>
      {store?.fromAdmin && (
        <div className="access-container flex start">
          <i>
            Accessing{" "}
            {(store?.info?.firstName ?? "") +
              " " +
              (store?.info?.lastName ?? "")}{" "}
            account
          </i>
          {/*<Button type="primary" onClick={handleAdmin}>*/}
          {/*  Back to admin dashboard*/}
          {/*</Button>*/}
        </div>
      )}
      {store.roles.includes("artist") && showAlert && <Alert style={{width:'100%'}} message="We are experiencing delays in processing applications for fiscal sponsorship. We will resume accepting and declining applications on April 13, 2025. Thank you for your patience." closable type="warning" showIcon/>}
      <DashboardLayoutContainer
        className={`admin-layout-container dashboard-layout-container flex ${(store?.fromAdmin && "admin-active") || ""
          } ${isMobile && "mobile"}`}
      >
        {isMobile ? (
          <DashboardHeaderMobile onClickMenu={()=>{
            setShowAlert(false)
          }}/>
        ) : (
          <aside className="flex column">
            <Link to={store?.info?.roles?.includes(UserRole.ARTIST) ? "/dashboard/income" : "/dashboard/history"}>
              <Logo />
            </Link>
            <MenuLayout disabled={store.info?.status === 'deactived'} />
            <div className="admin-user-container site-drawer-render-in-current-wrapper">
              <Collapse expandIconPosition="right" defaultActiveKey={store?.info?.status === 'deactived' ? '1' : undefined}>
                <Collapse.Panel
                  header={
                    <div className="user-wrap flex">
                      <span translate="no">
                        <img src={store?.info?.avatar || user_img} alt="user" />
                        {(store?.info?.firstName ?? "") +
                          " " +
                          (store?.info?.lastName ?? "")}{" "}
                      </span>
                    </div>
                  }
                  key="1"
                >
                  {store.info?.status === 'active' &&
                    <>
                      <NavLink to="/dashboard/setting">Account settings</NavLink>
                      <NavLink to="/dashboard/transactions"> Transaction History </NavLink>
                      {
                        store?.roles?.includes("donor") &&
                        <NavLink to="/dashboard/recurring">Recurring Payment Settings</NavLink>
                      }
                      {store?.roles?.includes("artist") && (
                        <NavLink to="/dashboard/document">Documents</NavLink>
                      )}
                    </>
                  }
                  <span onClick={onSignOut}>Sign out</span>
                </Collapse.Panel>
              </Collapse>
            </div>
          </aside>
        )}

        <main
          className={`content-main ${history.location.pathname.match(/message/) && "msg"
            }`}
        >
          {children}
        </main>
      </DashboardLayoutContainer>
    </>
  );
};

export default DashboardLayout;
