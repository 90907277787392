import {
  Form,
  Input,
  Button,
  Tooltip,
  message,
  Breadcrumb,
  Tag,
  Radio,
  Space,
  Menu,
  Dropdown,
  Modal,
  Divider,
} from "antd";
import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./SponsorshipReview.less";
import "@/assets/common.less";

import { useHistory } from "react-router-dom";
import {
  getSponsor,
  saveSponsor,
  cancelSponsorship,
} from "@/api/merbership-api";

import { KV } from "use-reaction";
import { flatten, unflatten } from "@/utils/util";
import { membershipStatus } from "@/types/enum";

// import { ReactComponent as EditOutlined } from '@/images/edit.svg'
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import Back from "@/components/Back";
import SelectAndInput from "@/components/Select/SelectAndInput";
import LimitedWordTextarea from "@/components/Input/LimitedWordTextarea";
import { TheField } from "@/components/GoogleTranslate";

export enum SponsorshipStatus {
  NO_APPLY = "no_apply",
  IN_REVIEW = "in_review",
  APPROVED = "approved",
  REJECTED = "rejected",
  PAID = "paid",
  COMPLETED = "completed",
}
type ArtistInfo = {
  disciplines: string[];
  website: string;
  biography: string;
  missionAndGoals: string;
  applySubsidized: boolean;
  benefit: string;
  valueAlignment: string;
};

type PersonInfo = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string[];
};
type SponsorInfo = {
  id: number;
  artistInformation?: ArtistInfo;
  personalInformation?: PersonInfo;
  signatureInfo?: KV;
  status?: SponsorshipStatus;
  note?: string;
  userId?: number;
};
const initInfo: SponsorInfo = {
  id: 0,
};

const SponsorshipReview = () => {
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [info, setInfo] = useState<any>(initInfo);
  const [formRef] = Form.useForm();

  const [applySubsidized, setApplySubsidized] = useState("");

  const loadInfo = async () => {
    const res = await getSponsor({});
    setInfo(res);
    setApplySubsidized(res?.artistInformation?.applySubsidized ? "yes" : "no");
    const disciplines = res.artistInformation?.disciplines;
    delete res.artistInformation?.artistInformation;
    const data = flatten({ ...res });
    data["artistInformation.disciplines"] = disciplines;
    data["artistInformation.applySubsidized"] = res?.artistInformation
      ?.applySubsidized
      ? "yes"
      : "no";
    data["artistInformation.previouslySponsored"] = res?.artistInformation
      ?.previouslySponsored || "No";
    formRef.setFieldsValue(data);
  };

  const onFinish = (values: any) => {
    values["artistInformation.applySubsidized"] =
      applySubsidized === "yes" ? true : false;
    const _data = unflatten(values);
    console.log("Request", _data);
    saveSponsor(_data).then((data: any) => {
      console.log("Response", data);
      setIsEdit(false);
      loadInfo();
    });
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  const onCancelSponsorship = () => {
    cancelSponsorship().then((data) => {
      setIsDelete(false);
      message.success("Application successfully deleted");
      history.push("/dashboard/membership/fiscal");
    });
  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <div className="sponsorship-review">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/membership/fiscal">
          Fiscal Sponsorship
        </Breadcrumb.Item>
        <Breadcrumb.Item>View application</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex start top-bar">
        <h2 className="title">
          {info.personalInformation?.firstName}{" "}
          {info.personalInformation?.lastName}
        </h2>
        <div className="status-tag">
          <Tag
            color={
              membershipStatus[info?.status]
                ? membershipStatus[info?.status]?.color
                : "gray"
            }
          >
            {membershipStatus[info?.status]
              ? membershipStatus[info?.status]?.text
              : info?.status}
          </Tag>
          {info?.status === "in_review"
            ? info?.artistInformation.applySubsidized && (
                <Tag color="orange">Social Justice Pending</Tag>
              )
            : info?.allowSubsidized && (
                <Tag color="default">Social Justice Program</Tag>
              )}
        </div>

        {!isEdit &&
          [
            SponsorshipStatus.IN_REVIEW,
            SponsorshipStatus.REJECTED,
            SponsorshipStatus.APPROVED,
          ].includes(info?.status) && (
            <Dropdown
              className="filter-item"
              trigger={["click"]}
              overlay={() => (
                <Menu>
                  {info?.status !== SponsorshipStatus.APPROVED && (
                    <Menu.Item
                      onClick={() => {
                        setIsEdit(true);
                      }}
                    >
                      Edit Application
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => {
                      setIsDelete(true);
                    }}
                  >
                    Delete Application
                  </Menu.Item>
                </Menu>
              )}
            >
              <span className="action">
                <FilterMore />
              </span>
            </Dropdown>
          )}
      </div>
      <div className="des">
        In order to publish and event, you will have to submit the content for
        <TheField/> to review. The approval process will take no more than 5
        business days
      </div>

      <div className={`review-card-container ${!isEdit ? "show" : "hidden"}`}>
        <div className="card-wrap">
          <h2 className="sub-title">Sponsored artist information</h2>
          <p className="flex">
            Artist Name
            <span>
              {info.personalInformation?.firstName}{" "}
              {info.personalInformation?.lastName}
            </span>
          </p>
          <p className="flex">
            Company
            <span>{info.personalInformation?.companyName || "N/A"}</span>
          </p>
          <p className="flex">
            Email address
            <span>{info.personalInformation?.email}</span>
          </p>
          <p className="flex">
            Phone number
            <span>{info.personalInformation?.phone[0]}</span>
          </p>
          <p className="flex">
            Alternate phone number
            <span>
              {info.personalInformation?.phone.length > 1
                ? info.personalInformation?.phone[1]
                : "N/A"}
            </span>
          </p>
          {/* <p className="flex">
            Primary Discipline
            <span> {info?.artistInformation?.primaryTag}</span>
          </p> */}
          <p className="flex">
            Discipline
            <span> {info?.artistInformation?.disciplines?.join(",")}</span>
          </p>
          <p className="flex">
            Website
            <span>{info?.artistInformation?.website}</span>
          </p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Artist biography</h2>
          <p>{info?.artistInformation?.biography}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Artist practice and current projects</h2>
          <p>{info?.artistInformation?.practice}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Mission and Goals</h2>
          <p>{info?.artistInformation?.missionAndGoals}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">
            <div className="sub-title-wrap">
              Subsidized Fiscal Sponsorship for Social Justice Arts
              Practitioners
              <Tag
                className="status-tag"
                color={
                  info?.artistInformation?.applySubsidized ? "green" : "red"
                }
              >
                {info?.artistInformation?.applySubsidized ? "Yes" : "No"}
              </Tag>
            </div>
          </h2>
          {info?.artistInformation?.applySubsidized && (
            <div className="subsidized-wrap-container">
              <div className="subsidized-wrap">
                <div className="subsidized-title">Have you been previously sponsored by <TheField/>? &nbsp; &nbsp;<Tag
                className="status-tag"
                color={
                  info?.artistInformation?.previouslySponsored === 'Yes' ? "green" : "red"
                }
              >
                {info?.artistInformation?.previouslySponsored}
              </Tag></div>
              </div>
              <Divider />
              {info?.artistInformation?.previouslySponsored === 'Yes' && <><div className="subsidized-wrap">
                <div className="subsidized-title">Please explain how your artistic practice has changed since then. </div>
                <p>{info?.artistInformation?.previouslySponsoredExplain}</p>
              </div>
              <Divider /></>}

              <div className="subsidized-wrap">
                <div className="subsidized-title">Value Alignment</div>
                <div className="subsidized-sub-title">
                  Describe how your creative work and mission aligns with The
                  Field's Social Justice Values. How are you putting these
                  values into practice?
                </div>
                <p>{info?.artistInformation?.valueAlignment}</p>
              </div>
              <Divider />
              <div className="subsidized-wrap">
                <div className="subsidized-title">Benefit</div>
                <div className="subsidized-sub-title">
                  Describe how you would benefit from a complimentary year of
                  Membership with Fiscal Sponsorship, a free consultation and
                  any of the other services <TheField/> offers. For reference,
                  read through the benefits offered by <TheField/>’s Memberships
                  and the article in our Knowledge Base, Fiscal Sponsorship For
                  Artists: An Overview.
                </div>
                <p>{info?.artistInformation?.benefit}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <Form
        form={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className={`${isEdit ? "show" : "hidden"}`}
      >
        <h3 className="sub-title">Applicant information</h3>
        <Form.Item label="First name" name="personalInformation.firstName">
          <Input placeholder="Enter First name" disabled />
        </Form.Item>
        <Form.Item label="Last name" name="personalInformation.lastName">
          <Input placeholder="Enter Last name" disabled />
        </Form.Item>
        <Form.Item label="Company name" name="personalInformation.companyName">
          <Input placeholder="Enter Company name" disabled />
        </Form.Item>
        <Form.Item label="Email address" name="personalInformation.email">
          <Input placeholder="Enter Email address" disabled />
        </Form.Item>
        <div className="row flex">
          <Form.Item label="Phone number" name="personalInformation.phone.[0]">
            <Input placeholder="Enter Phone number" disabled />
          </Form.Item>
          <Form.Item
            label="Alternate phone number"
            name="personalInformation.phone.[1]"
          >
            <Input placeholder="Enter alternate phone number" disabled />
          </Form.Item>
        </div>
        <Form.Item
          label={
            <div>
              Disciplines
              <Tooltip
                className="tooltip-field-item"
                title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
              >
                <QuestionCircleFilled />
              </Tooltip>
            </div>
          }
          name="artistInformation.disciplines"
          rules={[
            {
              required: false,
              type: "array",
              message: "Please select your disciplines",
            },
          ]}
        >
          <SelectAndInput disabled />
        </Form.Item>
        <Form.Item label="Website" name="artistInformation.website">
          <Input placeholder="Enter Website" />
        </Form.Item>
        <Form.Item
          name="artistInformation.biography"
          label={
            <div>
              BIOGRAPHY
              <Tooltip
                overlayInnerStyle={{
                  background: "#111",
                  color: "#A2A9AF",
                  width: 600,
                }}
                title="Please submit a detailed artistic biography. How did you begin? How long have you been doing it? What are some of your accomplishments? What are you hoping to do next?"
              >
                <InfoCircleFilled
                  style={{
                    fontSize: 14,
                    color: "#696969",
                    position: "absolute",
                    right: -4,
                    top: -2,
                  }}
                />
              </Tooltip>
            </div>
          }
          className="textarea-wrap"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter biography",
            },
            {
              validator: (_, value) => {
                if (value?.split(/\s+/g)?.length > 300) {
                  return Promise.reject(
                    new Error("Biography is limited to 300 words")
                  );
                } else {
                  return Promise.resolve();
                }
              },
              validateTrigger: "blur",
            },
          ]}
        >
          <LimitedWordTextarea
            rows={4}
            limit={300}
            placeholder="Please submit a detailed artistic biography. How did you begin? How long have you been doing it? What are some of your accomplishments? What are you hoping to do next?"
          />
        </Form.Item>
        <Form.Item
          name="artistInformation.practice"
          label={
            <div>
              ARTISTIC PRACTICE + CURRENT PROJECTS
              <Tooltip
                overlayInnerStyle={{
                  background: "#111",
                  color: "#A2A9AF",
                  width: 600,
                }}
                title="Please tell us about your artistic practice and current projects. What kind of art do you make, how, where, with whom do you work? Please include a description of how your work is publically available (i.e. workshops, performances, exhibitions, etc.) and, when are you planning for it to happen."
              >
                <InfoCircleFilled
                  style={{
                    fontSize: 14,
                    color: "#696969",
                    position: "absolute",
                    right: -4,
                    top: -2,
                  }}
                />
              </Tooltip>
            </div>
          }
          className="textarea-wrap"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter practice and current projects",
            },
            {
              validator: (_, value) => {
                if (value?.split(/\s+/g)?.length > 300) {
                  return Promise.reject(
                    new Error("Practice and projects is limited to 300 words")
                  );
                } else {
                  return Promise.resolve();
                }
              },
              validateTrigger: "blur",
            },
          ]}
        >
          <LimitedWordTextarea
            rows={4}
            limit={300}
            placeholder="Please tell us about your artistic practice and current projects. What kind of art do you make, how, where, with whom do you work? Please include a description of how your work is publically available (i.e. workshops, performances, exhibitions, etc.) and, when are you planning for it to happen."
          />
        </Form.Item>
        <Form.Item
          name="artistInformation.missionAndGoals"
          label={
            <div>
              MISSION + FUNDRAISING GOALS
              <Tooltip
                overlayInnerStyle={{
                  background: "#111",
                  color: "#A2A9AF",
                  width: 600,
                }}
                title="Describe the mission of your artistic practice or organization: what drives your work, the intended audience, and desired impact. Additionally, tell us how partnering with The Field as your fiscal sponsor will help you achieve your mission? Tell us about your plans for fundraising (e.g. grant applications, crowdfunding, in kind donations, etc.)."
              >
                <InfoCircleFilled
                  style={{
                    fontSize: 14,
                    color: "#696969",
                    position: "absolute",
                    right: -4,
                    top: -2,
                  }}
                />
              </Tooltip>
            </div>
          }
          className="textarea-wrap"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter Mission and Goals",
            },
            {
              validator: (_, value) => {
                if (value?.split(/\s+/g)?.length > 300) {
                  return Promise.reject(
                    new Error("Mission and Goals is limited to 300 words")
                  );
                } else {
                  return Promise.resolve();
                }
              },
              validateTrigger: "blur",
            },
          ]}
        >
          <LimitedWordTextarea
            rows={4}
            limit={300}
            placeholder="Describe the mission of your artistic practice or organization: what drives your work, the intended audience, and desired impact. Additionally, tell us how partnering with The Field as your fiscal sponsor will help you achieve your mission? Tell us about your plans for fundraising (e.g. grant applications, crowdfunding, in kind donations, etc.)."
          />
        </Form.Item>

        {/* HIDE the Yes/No question that opens the additional Subsidized… questions on the artist side, so that all new submitted applications are for regular fiscal sponsorship ONLY */}
        {/* <div className="application-subsidized-social-title">
          Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners
        </div>
        <div className="application-subsidized-social-note">
          <TheField/>’s Fiscal Sponsorship for Social Justice Arts Practitioners
          Program is a subsidized year of fiscal sponsorship with special
          benefits for artists who identify as members of a historically
          underrepresented or marginalized group and whose creative work puts
          into practice and furthers values of social justice, equity, and
          inclusion in their communities. We at <TheField/> understand that
          marginalized artists who engage in “Artivism” often face unique
          financial challenges as a result of creating work which questions the
          status quo. This program aims to address this issue by providing
          access and opportunity in the form of grant reviews, consultation
          calls, and fundraising support.{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.thefield.org/wp-content/uploads/2022/07/2022-The-Field-Fiscal-Sponsorship-for-Social-Justice-Arts-Practitioners-Program_Blank.pdf"
          >
            See important details about the program here.
          </a>
        </div>
        <Form.Item
          name="artistInformation.applySubsidized"
          label={
            <div>
              Are you interested in applying for a free first year of fiscal
              sponsorship under our Subsidized Fiscal Sponsorship program?
            </div>
          }
          className="radio-wrap"
          rules={[
            {
              required: true,
              whitespace: true,
              message:
                "Should populate the selection of Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners",
            },
            {
              validator: (_, value) => {
                if (value) {
                  return Promise.resolve();
                } else {
                  const error =
                    "Should populate the selection of Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners";
                  return Promise.reject(error);
                }
              },
            },
          ]}
        >
          <Radio.Group
            className="radio-selection"
            onChange={(e) => setApplySubsidized(e.target.value)}
          >
            <Space direction="vertical">
              <Radio value="yes">Yes, please</Radio>
              <Radio value="no">
                No, thanks, I’ll continue with the standard application.
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item> */}

        {/* {applySubsidized === "yes" && (
          <div>
            <Form.Item
              name="artistInformation.previouslySponsored"
              label='Have you been previously sponsored by The Field?'
              className="radio-wrap"
              rules={[
                {
                  required: true,
                  whitespace: true
                }
              ]}
            >
              <Radio.Group className="radio-selection" >
                <Space direction="vertical">
                  <Radio value='Yes'>Yes</Radio>
                  <Radio value='No'>No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle dependencies={["artistInformation.previouslySponsored"]}>
              {()=>{
                const temp = formRef.getFieldValue("artistInformation.previouslySponsored");
                if(temp === 'Yes'){
                  return <Form.Item
                  name="artistInformation.previouslySponsoredExplain"
                  label='Please explain how your artistic practice has changed since then.'
                  className="textarea-wrap"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please explain how your artistic practice has changed since then. ",
                    },
                    {
                      validator: (_, value) => {
                        if (value?.split(/\s+/g)?.length > 300) {
                          return Promise.reject(
                            new Error("Explain is limited to 300 words")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                      validateTrigger: "blur",
                    },
                  ]}
                >
                  <LimitedWordTextarea
                    rows={4}
                    limit={300}
                    placeholder="Please explain how your artistic practice has changed since then. "
                  />
                </Form.Item>
                }
              }}
            </Form.Item>
            <Form.Item
              name="artistInformation.valueAlignment"
              label={
                <div>
                  Value Alignment
                  <Tooltip
                    overlayInnerStyle={{
                      background: "#111",
                      color: "#A2A9AF",
                      width: 600,
                    }}
                    title="Describe how your creative work and mission aligns with The Field's Social Justice Values. How are you putting these values into practice?"
                  >
                    <InfoCircleFilled
                      style={{
                        fontSize: 14,
                        color: "#696969",
                        position: "absolute",
                        right: -4,
                        top: -2,
                      }}
                    />
                  </Tooltip>
                </div>
              }
              className="textarea-wrap"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter Value Alignment",
                },
                {
                  validator: (_, value) => {
                    if (value?.split(/\s+/g)?.length > 300) {
                      return Promise.reject(
                        new Error("Value Alignment is limited to 300 words")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                  validateTrigger: "blur",
                },
              ]}
            >
              <LimitedWordTextarea
                rows={4}
                limit={300}
                placeholder="Describe how your creative work and mission aligns with The Field's Social Justice Values. How are you putting these values into practice?"
              />
            </Form.Item>
            <Form.Item
              name="artistInformation.benefit"
              label={
                <div>
                  Benefit
                  <Tooltip
                    overlayInnerStyle={{
                      background: "#111",
                      color: "#A2A9AF",
                      width: 600,
                    }}
                    title="Why is a free year of fiscal sponsorship important to you right now? What goals do you hope to be able to achieve if you are accepted?"
                  >
                    <InfoCircleFilled
                      style={{
                        fontSize: 14,
                        color: "#696969",
                        position: "absolute",
                        right: -4,
                        top: -2,
                      }}
                    />
                  </Tooltip>
                </div>
              }
              className="textarea-wrap"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter Benefit",
                },
                {
                  validator: (_, value) => {
                    if (value?.split(/\s+/g)?.length > 300) {
                      return Promise.reject(
                        new Error("Benefit is limited to 300 words")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                  validateTrigger: "blur",
                },
              ]}
            >
              <LimitedWordTextarea
                rows={4}
                limit={300}
                placeholder="Why is a free year of fiscal sponsorship important to you right now? What goals do you hope to be able to achieve if you are accepted?"
              />
            </Form.Item>
          </div>
        )} */}
      </Form>
      <div className="row flex end">
        {isEdit ? (
          <div className="flex">
            <Back onClick={() => setIsEdit(false)}>Cancel</Back>
            <Button
              onClick={() => formRef.submit()}
              type="primary"
              className="btn-draft"
            >
              Save changes
            </Button>
          </div>
        ) : (
          info?.status === "in_review"
        )}
      </div>

      <Modal
        visible={isDelete}
        width={420}
        closable={false}
        footer={[
          <Button key="back" onClick={() => setIsDelete(false)}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onCancelSponsorship();
            }}
          >
            Confirm
          </Button>,
        ]}
      >
        <h2>Delete application?</h2>
        <p>
          {
            "Deleting this application will remove your current fiscal sponsorship approval.  If you want to reapply in the future, you will have to start from the beginning via your Membership > Fiscal Sponsorship page."
          }
        </p>
      </Modal>
    </div>
  );
};

export default SponsorshipReview;
